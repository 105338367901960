<template>
  <v-row no-gutters class="d-flex justify-center">
    <v-col cols="12">
      <div class="project-swiper my-5">
        <swiper
          loop
          @swiper="onSwiper"
          :spaceBetween="0"
          :slidesPerView="1"
          :slidesPerGroup="1"
          :centeredSlides="false"
          :autoplay="{ delay: 2000, disableOnInteraction: false }"
          :resistance="true"
          :resistanceRatio="0.85"
          @resize="updateSwiperSettings"
        >
          <swiper-slide v-for="(group, i) in groupedData" :key="i">
            <v-row class="pa-0" style="margin: 0 -8px !important">
              <v-col
                v-for="(item, j) in group"
                :key="j"
                :cols="12 / slidesPerView"
                class="pa-0 ma-0"
              >
                <v-card
                  class="ma-0 d-flex align-center flex-column ma-2 pt-4 pb-4 pl-2 pr-2"
                  color="#fff"
                  height="174"
                >
                  <v-rating
                    :model-value="item.score"
                    readonly
                    :size="18"
                    half-increments
                    color="yellow-darken-3"
                    class="evaluate-rating mb-2"
                  ></v-rating>
                  <div
                    class="evaluate-text text-center pb-1 pt-1 custom-scrollbar fc-black-09"
                  >
                    {{ item.content }}
                  </div>
                  <span class="evaluate-time mt-2 fc-black-06">{{
                    item.reviewDate
                  }}</span>
                </v-card>
              </v-col>
            </v-row>
          </swiper-slide>
        </swiper>
        <div
          class="button-prev btn btn-small"
          @click.stop="prevEl"
          v-if="isOneScreen"
        >
          <v-icon class="mdi mdi-chevron-left" size="30"></v-icon>
        </div>
        <div
          class="button-next btn btn-small"
          @click.stop="nextEl"
          v-if="isOneScreen"
        >
          <v-icon class="mdi mdi-chevron-right" size="30"></v-icon>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

const props = defineProps({
  dataList: {
    type: Array,
    default: () => []
  }
});
watch(
  () => props.dataList,
  newData => {
    if (newData && newData.length) {
      groupDataList();
    }
  }
);
const slidesPerView = ref(1); // 每屏显示的滑块数量
const isOneScreen = ref(true); // 是否是一屏
let useSwiper = ref(null); // swiper 实例
const groupedData = ref([]); // 转换后的二维数组
//获取当前屏幕宽度
const getScreenWidth = () => {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
};
// 设置当前显示的评价个数
const setslidesPerViewFn = num => {
  slidesPerView.value = num;
  if (props?.dataList?.length <= num) isOneScreen.value = false;
};
// 根据不同屏幕获取当前展示的评价个数
const updateSwiperSettings = () => {
  isOneScreen.value = true;
  const screenWidth = getScreenWidth();
  switch (true) {
    case screenWidth >= 1440:
      setslidesPerViewFn(4);
      break;
    case screenWidth >= 1024:
      setslidesPerViewFn(3);
      break;
    case screenWidth >= 640:
      setslidesPerViewFn(2);
      break;
    default:
      setslidesPerViewFn(1);
  }
  groupDataList();
};
// 处理获取到的数据转为二维数组
const groupDataList = () => {
  const dataList = props.dataList;
  const perPage = slidesPerView.value;
  const newGroupedData = [];
  for (let i = 0; i < dataList.length; i += perPage) {
    newGroupedData.push(dataList.slice(i, i + perPage));
  }
  groupedData.value = newGroupedData;
};
// 初始化swiper
const onSwiper = swiper => {
  useSwiper.value = swiper;
  updateSwiperSettings();
};
// 上一下
const prevEl = () => {
  useSwiper.value && useSwiper.value.slidePrev();
};
// 下一页
const nextEl = () => {
  useSwiper.value && useSwiper.value.slideNext();
};

// 初始化时和窗口大小改变时更新
onMounted(() => {
  window.addEventListener("resize", updateSwiperSettings);
  updateSwiperSettings();
});
// 注销方法
onUnmounted(() => {
  window.removeEventListener("resize", updateSwiperSettings);
});
</script>

<style scoped lang="scss">
.project-swiper {
  position: relative;
  min-height: 222px;
  .swiper-slide {
    width: 100% !important; /* 确保滑块宽度适配屏幕 */
  }
  .btn {
    position: absolute;
    top: 50%;
    height: 50px;
    width: 40px;
    background: white;
    opacity: 0.7;
    cursor: pointer;
    border-radius: 5px;
    line-height: 50px;
    text-align: center;
    margin-top: -25px;
    &.btn-small {
      width: 30px;
      height: 40px;
      line-height: 40px;
      margin-top: -20px;
    }
  }
  .btn:hover {
    opacity: 1;
  }
  .button-prev {
    left: -40px;
  }
  .button-next {
    right: -40px;
  }
}
@media (max-width: 960px) {
  .project-swiper {
    padding: 0 40px;
    .button-prev {
      left: 0;
    }
    .button-next {
      right: 0;
    }
  }
}
</style>
